import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, Subscriber} from 'rxjs';
import {NationaalGeoregisterSuggest} from '../../interfaces/external-services/nationaal-georegister-suggest';
import {NationaalGeoregisterRevGeo, NationaalGeoregisterRevGeoServerDocs} from '../../interfaces/external-services/nationaal-georegister-rev-geo';
import {NationaalGeoregisterLookup, NationaalGeoregisterLookupServerDocs} from '../../interfaces/external-services/nationaal-georegister-lookup';

@Injectable()

export class PdokService {
  constructor(private http: HttpClient,
  ) {
  }

  doReverseSearch(coords: [number, number]): Observable<NationaalGeoregisterRevGeoServerDocs> {
    return new Observable<NationaalGeoregisterRevGeoServerDocs>((o: Subscriber<NationaalGeoregisterRevGeoServerDocs>): void => {
      const url: string = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/reverse' +
        '?&lat=' + encodeURIComponent(coords[0]) +
        '&lon=' + encodeURIComponent(coords[1]) +
        '&rows=1&type=adres';

      this.http.get(url, {
        observe: 'response',
        responseType: 'json'
      }).subscribe(
        (response: HttpResponse<NationaalGeoregisterRevGeo>): void => {
          if (response.body.response.numFound > 0) {
            o.next(response.body.response.docs[0]);
          }
        },
        (): void => {
          o.next(undefined);
        }
      );
    });
  }

  getSearchedAddress(value: string): Observable<NationaalGeoregisterSuggest> {
    return new Observable<NationaalGeoregisterSuggest>((o: Subscriber<NationaalGeoregisterSuggest>): void => {
      const url: string = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest' +
        '?q=' + encodeURIComponent(value) +
        '&rows=10' +
        '&bq=' + encodeURIComponent('type:provincie^0.5') +
        '&bq=' + encodeURIComponent('type:gemeente^0.5') +
        '&bq=' + encodeURIComponent('type:woonplaats^0.5') +
        '&bq=' + encodeURIComponent('type:weg^0.5') +
        '&bq=' + encodeURIComponent('type:adres^1.5') +
        '&fq=bron:"BAG"';

      this.http.get(url, {
        observe: 'response',
        responseType: 'json'
      }).subscribe(
        (response: HttpResponse<NationaalGeoregisterSuggest>): void => {
          o.next(response.body);
        },
        (): void => {
          o.next(undefined);
        });
    });
  }

  selectSearchedAddress(id: string): Observable<NationaalGeoregisterLookupServerDocs> {
    return new Observable<NationaalGeoregisterLookupServerDocs>((o: Subscriber<NationaalGeoregisterLookupServerDocs>): void => {
      const url: string = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/lookup' +
        '?id=' + encodeURIComponent(id);

      this.http.get(url, {
        observe: 'response',
        responseType: 'json'
      }).subscribe(
        (response: HttpResponse<NationaalGeoregisterLookup>): void => {
          if (response.body.response.numFound > 0) {
            let data: NationaalGeoregisterLookupServerDocs = response.body.response.docs[0];
            const dataLatLng: string[] = data.centroide_ll.replace('POINT(', '').replace(')', '').split(' ');

            data = {...data, ...{lat: parseFloat(dataLatLng[1]), lng: parseFloat(dataLatLng[0])}};

            if (typeof data.postcode !== 'undefined') {
              o.next(data);
            } else {
              const url2: string = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest' +
                '?q=' + encodeURIComponent((data.straatnaam ?? '') + ' ' + (data.huisnummer ?? 1) + ' ' + data.woonplaatsnaam) +
                '&rows=1' +
                '&bq=' + encodeURIComponent('type:provincie^0.5') +
                '&bq=' + encodeURIComponent('type:gemeente^0.5') +
                '&bq=' + encodeURIComponent('type:woonplaats^0.5') +
                '&bq=' + encodeURIComponent('type:weg^0.5') +
                '&bq=' + encodeURIComponent('type:adres^1.5') +
                '&fq=(bron:"BAG") AND type:(adres AND postcode:*)'
              ;

              this.http.get(url2, {
                observe: 'response',
                responseType: 'json'
              }).subscribe(
                (response2: HttpResponse<NationaalGeoregisterSuggest>): void => {
                  if (response2.body.response.numFound > 0) {

                    const url3: string = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/lookup' +
                      '?id=' + encodeURIComponent(response2.body.response.docs[0].id);

                    this.http.get(url3, {
                      observe: 'response',
                      responseType: 'json'
                    }).subscribe(
                      (response3: HttpResponse<NationaalGeoregisterLookup>): void => {
                        if (response3.body.response.numFound > 0) {
                          const data2: NationaalGeoregisterLookupServerDocs = response3.body.response.docs[0];

                          o.next({...data, ...{postcode: data2.postcode}});
                        }
                      }
                    );
                  }
                });
            }
          }
        }
      );
    });
  }
}
