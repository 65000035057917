import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

@Component({
  selector: 'fury-removable-attachment',
  templateUrl: './removable-attachment.component.html',
  styleUrls: ['./removable-attachment.component.scss'],
})

export class RemovableAttachmentComponent {

  @Input() attachment: File = null;
  @Input() removable: boolean = true;
  @Input() invalid: boolean = false;
  @Input() index: number = null;

  @Output() destroy: EventEmitter<number> = new EventEmitter<number>();

  @HostBinding('className') className = 'fury-removable-attachment';

  constructor() {
  }

  removeAttachment(): void {
    this.destroy.emit(this.index);
  }
}
