import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import * as _ from 'lodash';

@Injectable()

export class FormService {

  public form: FormGroup = null;

  public errors = null;

  constructor() {
  }

  processErrors(responseErrors: any): void {
    this.errors = responseErrors;
    _.forEach(responseErrors, (errors, field: string): void => {
      const control = this.findFieldControl(field);
      const fieldErrors = this.fetchFieldErrors(errors);
      control.markAsTouched();
      control.updateValueAndValidity();
      control.setErrors(fieldErrors);
    });
  }

  findFieldControl(field: string): AbstractControl {
    let control: AbstractControl;
    if (field === 'base') {
      control = this.form;
    } else if (this.form.contains(field)) {
      control = this.form.get(field);
    } else if (field.match(/_id$/) && this.form.contains(field.substring(0, field.length - 3))) {
      control = this.form.get(field.substring(0, field.length - 3));
    } else if (field.indexOf('.') > 0) {
      control = this.form.get(field);
    } else {
      // Field is not defined in form but there is a validation error for it, set it globally
      control = this.form;
    }
    return control;
  }

  fetchFieldErrors(data: any): {} {
    const errors: string[] = [];

    data.forEach((error: string): void => {
      errors.push(error);
    });

    return errors;
  }

  getFieldError(field: string): string | boolean {
    const control = this.findFieldControl(field);
    let error = null;
    if (typeof control !== 'undefined') {
      if (typeof control.errors !== 'undefined') {
        if (control.errors !== null) {
          Object.keys(control.errors).map((key: any): void => {
            if (key === 'maxLengthError') {
              error = 'Max. toegestane karakters ' + control.errors[key].maxLength;
            } else if (key !== 'required' && key !== 'email') {
              error = control.errors[key];
            }
          });
        }
      }
    }
    return error;
  }
}
