import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ByteFormatPipe} from '../../utils/byte-format';
import {RemovableAttachmentComponent} from './removable-attachment.component';
import {FlexModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  imports: [CommonModule, FlexModule, MatIconModule, MatButtonModule, MatInputModule],
  declarations: [
    ByteFormatPipe,
    RemovableAttachmentComponent
  ],
  exports: [
    ByteFormatPipe,
    RemovableAttachmentComponent,
  ]
})

export class RemovableAttachmentModule {
}
