<div>
    <div class="filename">
        <span class="dot" [class.error]="invalid"></span>
        <span class="filename">{{ attachment.name }}</span>
    </div>
    <div class="details">
        <span class="size">{{ attachment.size | byteFormat }}</span>
        <span *ngIf="removable" class="remove">
            <button (click)="removeAttachment()" class="remove-attachment" type="button">Verwijder dit bestand</button>
        </span>
    </div>
</div>
<mat-hint class="mat-error" *ngIf="invalid">Het bestandsformat van deze bijlage kan niet worden verstuurd.</mat-hint>
