import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as FileSaver from 'file-saver';
import {Observable, of, throwError} from 'rxjs';
import {SnackbarService} from './snackbar.service';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ServerResponse} from '../../interfaces/base/server.response';
import {catchError, map} from 'rxjs/operators';
import {FileInput} from 'ngx-material-file-input';
import {FormService} from './form.service';

@Injectable()

export class ApiService {

  public generalError: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  private ignoreFormFields: string[] = [
    'attachmentsContainer',
  ];

  constructor(private http: HttpClient,
              private snackbarService: SnackbarService,
              public formService: FormService,
  ) {
  }

  get isOnline(): boolean {
    return !!window.navigator.onLine;
  }

  get(
    url: string,
  ): Observable<ServerResponse> {
    if (this.isOnline) {
      return this.http.get(environment.api_endpoint + url, {
        observe: 'response',
        responseType: 'json',
      })
        .pipe(
          map((response: HttpResponse<ServerResponse>) => this.handleResponse(response)),
          catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
        );
    } else {
      this.notAvailableOffline();
      return of();
    }
  }

  post(url: string, formData: any): Observable<ServerResponse> {
    if (this.isOnline) {
      return this.http.post(environment.api_endpoint + url, (formData ? this.processFormData(formData) : null), {
        observe: 'response',
        responseType: 'json',
      })
        .pipe(
          map((response: HttpResponse<ServerResponse>) => this.handleResponse(response)),
          catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
        );
    } else {
      this.notAvailableOffline();
      return of();
    }
  }

  download(url: string, filename: string = null, mimeType: string = 'application/octet-stream'): void {
    if (this.isOnline) {
      this.http.get<Blob>(environment.api_endpoint + url, {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
        .subscribe(
          (response: HttpResponse<Blob>) => this.handleFileDownload(response, filename, mimeType),
          (error: HttpErrorResponse) => this.handleErrorResponse(error)
        );
    } else {
      this.notAvailableOffline();
    }
  }

  handleResponse(res: HttpResponse<ServerResponse>): ServerResponse {
    this.generalError.next(false);
    if (typeof res.body !== 'undefined') {

      if (typeof res.body.message !== 'undefined') {
        this.snackbarService.success(res.body.message);
      }

      return res.body;
    }
  }

  handleFileDownload(response: HttpResponse<Blob>, filename: string = null, mimeType: string = 'application/octet-stream'): void {
    const header: string = response.headers.get('content-disposition'),
      responseFilename: string = header.split(';')[1].trim().split('=')[1].replace(/"/g, '');
    FileSaver.saveAs(
      new Blob([response.body], {type: mimeType}),
      (filename !== null ? filename : (responseFilename !== null ? responseFilename : null))
    );
  }

  handleErrorResponse(error: HttpErrorResponse): Observable<ServerResponse> {
    this.generalError.next(true);

    if (typeof error.error !== 'undefined' && typeof error.error.message !== 'undefined') {
//      this.snackbarService.error(error.error.message);
    } else {
      this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
    }

    if (typeof error.error !== 'undefined' && error.error !== null) {
      if (typeof error.error.errors !== 'undefined') {
        this.formService.processErrors(error.error.errors);
      }
    }

    return throwError(error.error);
  }

  isErrorResponse(response: ServerResponse): boolean {
    return !(typeof response.errors === 'undefined' && typeof response.exception === 'undefined' && response.status !== 500);
  }

  processFormData(formData: any): FormData | null {
    const postData: FormData = new FormData();

    if (typeof formData !== 'undefined') {
      if (formData !== null) {
        Object.keys(formData).forEach((key: string): void => {
          if (this.ignoreFormFields.indexOf(key) === -1) {
            if (formData[key] instanceof FileInput) {
              for (let i: number = 0; i < formData[key]['_files'].length; i++) {
                postData.append(
                  key + (formData[key]['_files'].length > 1 ? '[]' : ''),
                  formData[key]['_files'][i], formData[key]['_files'][i].name
                );
              }
            } else if (formData[key] instanceof File) {
              if (typeof formData[key].length === 'undefined') {
                postData.append(key, formData[key], formData[key].name);
              } else {
                for (let i: number = 0; i < formData[key].length; i++) {
                  postData.append(
                    key + (formData[key].length > 1 ? '[]' : ''),
                    formData[key][i], formData[key][i].name
                  );
                }
              }
            } else if (Array.isArray(formData[key])) {
              for (let i: number = 0; i < formData[key].length; i++) {
                if (/*formData[key][i] !== false && */formData[key][i] !== 'undefined') {
                  if (formData[key][i] instanceof FileInput) {
                    for (let j: number = 0; j < formData[key][i][j]['_files'].length; j++) {
                      postData.append(
                        key + (formData[key]['_files'].length > 1 ? '[]' : ''),
                        formData[key]['_files'][i], formData[key]['_files'][i].name
                      );
                    }
                  } else if (formData[key][i] instanceof File) {
                    if (typeof formData[key][i].length === 'undefined') {
                      postData.append(key + '[]', formData[key][i], formData[key][i].name);
                    } else {
                      for (let j: number = 0; j < formData[key][i].length; j++) {
                        postData.append(
                          key + '[]',
                          formData[key][i][j], formData[key][i][j].name
                        );
                      }
                    }
                  } else if (formData[key][i] instanceof Object) {
                    Object.keys(formData[key][i]).map((d: string): void => {
                      postData.append(key + '[' + i + '][' + d + ']', formData[key][i][d]);
                    });
                  } else {
                    postData.append(key + '[' + i + ']', formData[key][i]);
                  }
                }
              }
            } else if (formData[key] instanceof Object) {
              Object.keys(formData[key]).forEach((key2: string): void => {
                if (/*formData[key][key2] !== false && */formData[key][key2] !== 'undefined') {
                  if (key2.indexOf('[') !== -1) {
                    postData.append(key + key2, formData[key][key2]);
                  } else {
                    if (Array.isArray(formData[key][key2])) {
                      formData[key][key2].forEach((d: any): void => {
                        postData.append(key + '[' + key2 + '][]', d);
                      });
                    } else {
                      postData.append(key + '[' + key2 + ']', formData[key][key2]);
                    }
                  }
                }
              });
            } else {
              postData.append(key, formData[key]);
            }
          }
        });

        return postData;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  notAvailableOffline(): void {
    this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
  }
}
